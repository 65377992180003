@charset "utf-8";

// Width of the content area
$content-width:    800px;
$on-phone:         450px;
$on-palm:          660px;
$on-laptop:        1000px;

@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

// Import partials from `sass_dir` (defaults to `_sass`)
// Do not make any changes to this file. Go to /_sass and find the appropriate SCSS file and make changes there.
@import
        "variables",
        "footer-social",
        "projects",
        "profile",
        "footer",
        "style",
        "google-fonts"
;

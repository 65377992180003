/* Footer */
footer {
    clear: both;
    margin-top: 2em;
}

/* Footer navigation links */
.links a {
    margin-right: 1em;
}

/* Footer copyrights */
.copy {
    color: #aaa;
    text-align: center;
}

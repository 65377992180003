/* Social Icons for Footer from https://codepen.io/deutschland2k/pen/qKpdJX */

.rounded-social-buttons {
  text-align: center;
}

.rounded-social-buttons .social-button {
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 2.125rem;
  height: 2.125rem;
  border: 0.125rem solid transparent;
  padding: 0;
  text-decoration: none;
  text-align: center;
  color: #fefefe;
  font-size: 1.5625rem;
  font-weight: normal;
  line-height: 1.5em;
  border-radius: 1.6875rem;
  transition: all 0.5s ease;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
}

.rounded-social-buttons .social-button:hover, .rounded-social-buttons .social-button:focus {
  -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
          transform: rotate(360deg);
}

.rounded-social-buttons .fa-twitter,
.fa-facebook-f,
.fa-linkedin,
.fa-github,
.fa-orcid,
.fa-researchgate,
.fa-envelope {
  font-size: 25px;
}

.rounded-social-buttons .social-button.email {
  background: #60c17d;
}
.rounded-social-buttons .social-button.email:hover, .rounded-social-buttons .social-button.email:focus {
  color: #60c17d;
  background: #fefefe;
  border-color: #000000;
}


.rounded-social-buttons .social-button.gitlab {
  background: #FC6D27;
}
.rounded-social-buttons .social-button.gitlab:hover, .rounded-social-buttons .social-button.gitlab:focus {
  color: #FC6D27;
  background: #fefefe;
  border-color: #000000;
}


.rounded-social-buttons .social-button.mastodon {
  background: #2a90d9;
}
.rounded-social-buttons .social-button.mastodon:hover, .rounded-social-buttons .social-button.mastodon:focus {
  color: #2a90d9;
  background: #fefefe;
  border-color: #000000;
}


.rounded-social-buttons .social-button.matrix {
  background: #01bb84;
}
.rounded-social-buttons .social-button.matrix:hover, .rounded-social-buttons .social-button.matrix:focus {
  color: #01bb84;
  background: #fefefe;
  border-color: #000000;
}
